import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Link } from '../components/Link';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import ino from '../images/vp.png';
import ino2 from '../images/vpup.png';

const styles = (theme) => ({
  root: {},
});

class Charity extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <article>
            <header>
              <h1>
                <Text>KuSwap Marketplace</Text>
              </h1>
            </header>
            <p>
              <Text>
                Mint VixenPups on{' '}
                <a href="https://kuswap.finance/#/vixenpups">
                  KuSwap's launchpad
                </a>
                <br />
                Trade VixenPunks or VixenPups on{' '}
                <a href="https://nft.kuswap.finance/">KuSwap's NFT market</a>
              </Text>
            </p>
            <Fader>
              <img src={ino} alt="KuSwap INO" />
              <img src={ino2} alt="KuSwap INO2" />
            </Fader>
            <Fader>
              <blockquote>
                <p>
                  Vixen Punks is a collection of 2,222 unique Vixen Punk NFTs—
                  unique digital collectibles living on the KCC blockchain.
                </p>
              </blockquote>
              <p>
                Your Vixen is an open invitation into an unfolding Metaverse and
                grants access to benefits within the world.
                <br />
                <br />
                Our first drop “Origins” will be the introduction to the world
                and follow the story of a group of magical Fox-like beings on
                their journey to restore their lands.
                <br />
                <br />
                After the successful launch Vixen holders will begin to see and
                experience the unfolding of the Metaverse, receive great gifts
                and contribute to the ecosystem, if they wish to do so.
                <br />
                <br />
                Vixen Punks takes inspiration from the digital art of the great
                NFT collections and combines them with fantasy and folklore to
                create an engaging user experience that goes beyond just owning
                an image and aims to bring together a community of individuals
                with shared interests.
              </p>
              <blockquote>
                💠 “In the midst of chaos, there is also opportunity” 💠
              </blockquote>
            </Fader>
          </article>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Charity);
